import {
  all,
  takeEvery,
  put,
  fork,
} from "redux-saga/effects";
import types from "./Types";
import Endpoints from "./Endpoints";
import Api from "../../services/Api";
import { toast } from 'react-toastify';
import { loginCode } from "src/utils/loginCode";


export function* GetHyperLedgerList() {
  yield takeEvery(types.GET_HYPER_LEDGER_lIST, function* ({ data }) {
    let response = [];
    try {
      response = yield Api.Post(Endpoints.GetHyperLedgerList, true);
      if (response) {
        yield put({
          type: types.GET_HYPER_LEDGER_lIST_SUCCESS,
          data: { data: response },
        });
      } else
        yield put({
          type: types.GET_HYPER_LEDGER_lIST_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      yield put({
        type: types.GET_HYPER_LEDGER_lIST_FAIL,
      });
    }
  });
}

export function* AddHyperLedger() {
  yield takeEvery(types.ADD_HYPER_LEDGER, function* ({ data }) {

    let body = {

    }
    try {
      const response = yield Api.Post(Endpoints.AddHyperLedger, data, true);
      if (response) {
        yield put({
          type: types.ADD_HYPER_LEDGER_SUCCESS,
          data: { data: response },
        });
        toast.success("با موفقیت انجام شد", { position: toast.POSITION.BOTTOM_RIGHT });

      } else
        yield put({
          type: types.ADD_HYPER_LEDGER_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.ADD_HYPER_LEDGER_FAIL,
      });
    }
  });
}

export function* GetHyperLedgerDetail() {
  yield takeEvery(types.GET_HYPER_LEDGER_DETAILS, function* ({ data }) {
    try {
      const response = yield Api.Get(Endpoints.GetHyperLedgerDetail + `?id=${data.id}`, true);
      if (response) {
        yield put({
          type: types.GET_HYPER_LEDGER_DETAILS_SUCCESS,
          data: response,

        });
      } else
        yield put({
          type: types.GET_HYPER_LEDGER_DETAILS_EXCEPTION,
          data: {
            exception: response.status,
          },
        });
    } catch (e) {
      let selected = loginCode.filter((each) => each.code === e.response.data.DicCode);
      toast.error(selected.length > 0 ? selected[0].title : e.response.data.title, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      yield put({
        type: types.GET_HYPER_LEDGER_DETAILS_FAIL,
      });
    }
  });
}
export default function* HyperledgerFabricSagas() {
  yield all(
    [
      fork(GetHyperLedgerList),
      fork(AddHyperLedger),
      fork(GetHyperLedgerDetail)
    ]);
}