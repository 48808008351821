

import React, { useEffect } from "react";
import HyperLedgerFabricsActions from "src/redux/HyperledgerFabric/Actions";
import { useDispatch, useSelector } from "react-redux";
import { CardContainer } from "src/shared/generalStyles";
import { Box, Grid } from "@mui/material";
import { DetailContainer, OrgInfo } from "./style";
import {useParams} from "react-router-dom"

const HyperLedgerFabricDetail = (props) => {

    const dispatch = useDispatch();
    const {id} = useParams();

    const HyperLedgerFabricState = useSelector((state) => state.HyperledgerFabric);
    const { hyperLedgerFabricsDetail } = HyperLedgerFabricState;

    useEffect(() => {
        dispatch(HyperLedgerFabricsActions.getHyperLedgerDetails({ id: id}));
    }, []);
    ////////////////////////////////

    return (

        <CardContainer>
            <DetailContainer>
                <Grid container>
                    <Grid item md={3} p={1}>
                        <Box className="box-container">
                            <Box>نام سازمان:</Box>
                            <Box className="text_bold">
                                {hyperLedgerFabricsDetail?.name}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={3} p={1}>
                        <Box className="box-container">
                            <Box>نام سفارش دهنده:</Box>
                            <Box className="text_bold">
                                {hyperLedgerFabricsDetail?.orderer?.name}
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item md={3} p={1}>
                        <Box className="box-container">
                            <Box>پورت نود سفارش دهنده:</Box>
                            <Box className="text_bold">
                                {hyperLedgerFabricsDetail?.orderer?.port}
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item md={3} p={1}>
                        <Box className="box-container">
                            <Box>نوع سفارش دهنده:</Box>
                            <Box className="text_bold">
                                {hyperLedgerFabricsDetail?.orderer?.type}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    <Box className="text_bold" p={1}>
                    سازمان ها:
                    </Box>
                    {hyperLedgerFabricsDetail?.organizations?.map((_, index) => {
                        return (
                            <OrgInfo>
                                <Grid container>
                                    <Grid item md={4} display="flex">
                                        <Box display="flex" alignItems="center" px={1}> نام سازمان :</Box>
                                        <Box className="text_bold">
                                            {_.name}
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} display="flex">
                                        <Box display="flex" alignItems="flex-end" px={1}>MspId :</Box>
                                        <Box className="text_bold">
                                            {_.mspId}
                                        </Box>
                                    </Grid>
                                    <Grid item md={4} display="flex">
                                        <Box display="flex" alignItems="flex-end" px={1}>  تعداد peer در سازمان :</Box>
                                        <Box className="text_bold">
                                            {_.peers.length}
                                        </Box>
                                    </Grid>
                                    <Grid container item md={12} pt={2}>
                                        <Box className="text_bold" p={1}>
                                           peer ها:
                                        </Box>
                                        {_.peers.map((peer, ind) => {
                                            return (
                                                <Grid item md={12} display="flex" px={2}>
                                                    <Box>{ind + 1} -</Box>
                                                    <Box display="flex" alignItems="center" px={1}>نام  : {peer.name}</Box>
                                                    <Box display="flex" alignItems="center" px={1}>پورت  : {peer.port}</Box>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>

                                </Grid>
                            </OrgInfo>
                        )
                    })}
                </Grid>

                <Grid container>
                    <Box className="text_bold" p={1}>
                        کانال ها:
                    </Box>
                    {hyperLedgerFabricsDetail?.channels?.map((_, index) => {
                        return (
                            <OrgInfo>
                                <Grid container>
                                    <Grid item md={4} display="flex">
                                        <Box display="flex" alignItems="center" px={1}> نام کانال :</Box>
                                        <Box className="text_bold">
                                            {_.name}
                                        </Box>
                                    </Grid>

                                    <Grid container item md={12} pt={2}>
                                        <Box className="text_bold" p={1}>
                                           peer ها:
                                        </Box>
                                        {_.peers.map((peer, ind) => {
                                            return (
                                                <Grid item md={12} display="flex" px={2}>
                                                    <Box>{ind + 1} -</Box>
                                                    <Box display="flex" alignItems="center" px={1}>نام  : {peer.name}</Box>
                                                    <Box display="flex" alignItems="center" px={1}>پورت  : {peer.port}</Box>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </OrgInfo>
                        )
                    })}
                </Grid>
            </DetailContainer>
        </CardContainer >
    )
}

export default HyperLedgerFabricDetail;