import types from "./Types";
import Storage from "../../storage";
import Keys from "../../storage/NameSpace";
import { prepareSelectData } from "src/utils";
import RecordController from "src/controllers/RecordController";

const initState = {
  organizationList: [],
  organizationsListOptions: [],
  organizationsPeersOptions: [],

  organizationsPeersList: [],
  HyperledgerFabricStep: 1,
  channelList: [],
  orgNumInCompany: null,
  hyperLedgerFabricsList: [],
  orderrerInfo: {},
  networkName: null,

  takeHyperLedgerFabric:1000,
  skipHyperLedgerFabric:0,
  HyperLedgerFabricLoading :false,
  hasMore: false,
  count: 0,

};

export default function Reducer(state = initState, action) {
  switch (action.type) {

    case types.SET_ORGANIZATION_LIST:
      return {
        ...state,
        organizationList: action.data,
        organizationsListOptions: prepareSelectData("organizations", action.data)
      };
    case types.SET_ORGANIZATIONS_PEERS_LIST:
      return {
        ...state,
        organizationsPeersList: action.data
      };

    case types.SET_NEXT_HYPERLEGER_FABRIC_STEP:
      return {
        ...state,
        HyperledgerFabricStep: action.data
      };
    case types.SET_ORGANIZATIONS_INFO:
      return {
        ...state,
        orgNumInCompany: action.data.orgNumInCompany,
        networkName: action.data.networkName
      }
    case types.GET_HYPER_LEDGER_lIST_SUCCESS:
      return {
        ...state,
        hyperLedgerFabricsList: action.data.data
      };
    case types.ADD_HYPER_LEDGER_SUCCESS:

      return {
        ...state,
        hyperLedgerFabricsList: RecordController.add(
          state.hyperLedgerFabricsList,
          action.data
        ),
        HyperledgerFabricStep: 1,
      };
    case types.SET_ORDERER_INFO:
      return {
        ...state,
        orderrerInfo: action.data
      };
    case types.SET_ORGANIZATION_PEERS_OPTIONS:

      const newForms = [...state.organizationsPeersOptions];
      let findPeers = state.organizationsPeersList.filter((each) => each.mspId === action.data.value);
      newForms[action.data.index] = prepareSelectData("peers", findPeers[0].peers)
      return {
        ...state,
        organizationsPeersOptions: newForms
      }
    case  types.RESET_HYPER_FABRIC_STATE:
      return{
        ...state,
        HyperledgerFabricStep :1,
        organizationList: [],
        organizationsListOptions: [],
        organizationsPeersOptions: [],
        organizationsPeersList: [],
        channelList: [],
        orgNumInCompany: null,
        hyperLedgerFabricsList: [],
        orderrerInfo: {},
        networkName: null,
      }
      case types.GET_HYPER_LEDGER_DETAILS_SUCCESS :
        return{
          ...state,
          hyperLedgerFabricsDetail : action.data
        }
    default:
      return state;
  }
}